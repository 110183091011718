@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
#back-to-top-anchor {
	height: 0px;
	padding: 0px;
}

.headerContainer {
	position: relative;
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	overflow: visible;
}

.headerImageContainer {
	width: 100%;
	height: 50vh;
	text-align: center;
	overflow: hidden;
	position: relative;
}

.headerImage {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.logoContainer {
	width: 40px;
	height: 40px;
	background-color: white;
	border-radius: 50%;
	margin-bottom: -20px;
}

.logo {
	position: relative;
	width: 34px;
}

.item-enter {
    opacity: 0;
  }
.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}


