#back-to-top-anchor {
	height: 0px;
	padding: 0px;
}

.headerContainer {
	position: relative;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: visible;
}

.headerImageContainer {
	width: 100%;
	height: 50vh;
	text-align: center;
	overflow: hidden;
	position: relative;
}

.headerImage {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.logoContainer {
	width: 40px;
	height: 40px;
	background-color: white;
	border-radius: 50%;
	margin-bottom: -20px;
}

.logo {
	position: relative;
	width: 34px;
}
